export default [{
  header: 'Pages',
  icon: 'FileIcon',
  children: [
    {
      title: 'Home',
      route: 'home',
      icon: 'HomeIcon',
    },
    {
      title: 'Customers',
      route: 'customers',
      icon: 'FileIcon',
    },
  ],
}]
